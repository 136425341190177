import axios from "../utils/axios";
import { formatNodes } from "../helpers"

export const getNodes = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_LINK}fetchNodes`)
        return formatNodes(res.data);
    } catch (err) {
        throw err
    }
}

export const getService = async (token, serviceId) => {
    try {
        var config = {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        let response = await axios.get(`https://voxel3di.com/en/api/v1/customer/service/${serviceId}/`, config)
        if (response.status === 404)
            return false
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const updateService = async (token, serviceId, serviceData) => {
    try {
        var config = {
            method: 'put',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        var data = JSON.stringify({
            "service_data": serviceData,
        })
        let response = await axios.put(`https://voxel3di.com/en/api/v1/customer/service/${serviceId}/`, data, config)
        return response.data
    }
    catch(error) {
        console.log(error)
    }
}


export const addServiceFile = async (serviceId, file, fileName, token) => {
    try {
        const form = new FormData()
        form.append('file', file)
        form.append('name', fileName);
        form.append('service', serviceId);
        var config = {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await axios.post(`https://voxel3di.com/en/api/v1/customer/service-file/`,form, config)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const updateServiceFile = async (serviceId, fileId, file, fileName, token) => {
    try {
        const form = new FormData()
        form.append('file', file)
        form.append('name', fileName);
        form.append('service', serviceId);
        var config = {
            method: 'put',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await axios.put(`https://voxel3di.com/en/api/v1/customer/service-file/${fileId}/`,form, config)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const analysisLetralImage = async (serviceId, imageUrl, token) => {
    try {
        var config = {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        };
        var data = JSON.stringify({
            "request_data":{
                "scan_image": `${imageUrl}`
            },
            "response_data": {},
            "service": `${serviceId}`
        });
        let response = await axios.post(`https://voxel3di.com/en/api/v1/customer/ceph-ai/`, data, config)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const clasifyLetralImage = async (file) => {
    try {
        const form = new FormData()
        form.append('file', file)
        var config = {
            method: 'post',
        };
        let response = await axios.post(`https://www.ai-platform.link/predict`, form, config)
        return response.data
    } catch (error) {
        console.log(error)
    }
}


    

export const getBase64FromUrl = async (url) => {
    let targetPart, params, AWSAccessKeyId, Signature,Expires = ""
    if(url) {
        targetPart = url.split('?')[1] ?? ""
        params = targetPart.split('&') ?? ""
        AWSAccessKeyId =  params[0].split('=')[1] ?? ""
        Signature =  params[1].split('=')[1] ?? ""
        Expires = params[2].split('=')[1] ?? ""
    }
    try {
        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { },
            params: {
                AWSAccessKeyId: AWSAccessKeyId,
                Signature:  Signature,
                Expires:  Expires,
            },
            responseType: "blob"
        };
        let response = await axios(config)
        return response
    } catch (error) {
        console.log(error)
    }
}


export const downloadImage = (imageURL, name) => {
    getBase64FromUrl(imageURL).then( (blobImage) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blobImage.data);
        reader.onload = function() {
            const link = document.createElement('a')
            link.href = reader.result
            link.download = name
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const previewImage = (imageURL, name) => {
    const link = document.createElement('a')
    link.href = imageURL
    link.target = "_blank"
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}





